@import "../../global.scss";

.wrapper {
  width: 98%;
  margin-top: 10px;
  padding:10px 5px;
  background-color: $white-color;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  .headerRow {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
    .titleText {
      font-size: 24px;
      font-weight: 600;
    }
    .buttonWrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      .deleteOutlinedButton,
      .downloadOutlinedButton,
      .addEmojiButton {
        margin-left: 10px;
      }
      .downloadOutlinedButton {
        background-color: transparent;
        border: 1px solid $red-color;
      }
    }
  }
  .tabs{
    width: 95%;
    margin-top: 20px;
  }
}
