.collapse {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.5s ease-in-out, opacity 0.5s ease-in-out;
  opacity: 0;
}

.expand {
  max-height: none;
  transition: max-height 0.5s ease-in-out, opacity 0.5s ease-in-out;
  opacity: 1;
}
