.icon {
  margin-right: 5px;
}

.row {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .title {
    font-size: 16px;
    font-weight: 600;
    // color: #000;
    width: 70%;
  }

  .statusWrapper {
    .iconDone {
      @extend .icon;
      color: #52c41a;
    }
    .iconFail {
      @extend .icon;
      color: #ff4d4f;
    }
    .iconPending {
      @extend .icon;
      color: #faad14;
    }
    .iconQueue {
      @extend .icon;
      color: #c0c0c0;
    }
  }
}