.cardContainer {
  display: flex;
  height: 100px;
  width: 100%;
  border-radius: 5px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
  justify-content: space-between;
  align-items: center;
  margin: 6px 0px;
  padding: 5px 10px;

  .cardTitle {
    font-size: 24px;
    font-weight: 700;
  }

  .actionIcons {
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 15%;
  }
}
