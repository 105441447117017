.ant-tabs-nav {
  margin-top: 10px;
  .ant-tabs-nav-wrap {
    .ant-tabs-nav-list {
      .ant-tabs-tab {
        font-size: 18px;
      }
    }
  }
}
