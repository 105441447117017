@import "../../global.scss";

.layout {
  background-color: $white-color;

  .pageContent {
    background-color: $white-color;
    // padding: 50px 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 1440px;
    margin: auto;
    padding: 20px 0;
    gap: 40px;

    .section {
      display: flex !important;
      flex-direction: column !important;
      align-items: flex-start !important;
      justify-content: space-around;
      width: 100%;

      .firstTitle {
        margin-top: 0;
        font-size: 50px;
        line-height: 1.2;
        font-weight: bold;
        word-wrap: break-word;
      }
      .highlighted {
        word-wrap: break-word;
        font-size: 50px;
        color: #6165db;
      }

      .seconedParagraph {
        font-size: 24px;
      }
      .thirdParagraph {
        font-size: 18px;
        margin-bottom: 20px;
      }

      .startTrial {
        background-color: $main-background-color;
        color: $white-color;
        border-radius: 45px;
        font-weight: 700;
        text-decoration: none;
        font-size: 15px;
        font-weight: 800;
        width: 175px;
        height: 41px;
        padding: 10px 20px;
      }
    }

    .creatilSection {
      display: flex;
      flex-direction: column;
      height: "100%";
      width: 100%;
      // justify-content: center;
      // align-items: center;

      .titleWrapper {
        display: flex;
        // justify-content: center;
        // align-items: center;
        text-align: left;
        width: 100%;
        margin-bottom: 20px;
        align-self: flex-start;
        .sectionTitle {
          font-size: 24px;
          font-weight: 400;
        }
        .strongText {
          // width: 100%;
          text-align: left;
          font-size: 24px;
          font-weight: 700;
        }
      }
    }
    // .plansSectionWrapper {
    .plansSection {
      width: 100%;
      margin-top: 10px;
      padding: 20px;
      background-color: #f0fbff;
    }

    .title {
      font-size: 24px;
      font-weight: 800;
    }

    .ant-collapse-item {
      width: 100% !important;
    }
  }
}

@media only screen and (min-width: $tablet-screen) and (max-width: $medium-screen) {
  .pageContent {
    .section {
      align-items: center !important;

      .seconedParagraph {
        font-size: 14px !important;
      }
      .firstTitle {
        font-size: 20px !important;
      }
      .highlighted {
        font-size: 20px !important;
      }
      .thirdParagraph {
        font-size: 14px !important;
      }

      .creatilSection {
        .titleWrapper {
          .strongText {
            font-size: 20px !important;
          }
          .sectionTitle {
            font-size: 20px !important;
          }
        }
      }
    }

    padding: 30px 20px;
    .trialLink {
      width: 50% !important;
    }

    .collapse {
      width: 100%;
    }
  }
}

@media only screen and (max-width: $tablet-screen) {
  .layout {
    .pageContent {
      padding: 30px 20px;
      align-items: center !important;

      // .sectionWrapper {
      .section {
        align-items: center !important;
        justify-content: center;
        text-align: center;

        .seconedParagraph {
          font-size: 14px !important;
          text-align: center;
        }
        .firstTitle {
          font-size: 20px !important;
        }
        .highlighted {
          font-size: 20px !important;
        }
        .thirdParagraph {
          font-size: 14px !important;
        }
        .sectionFirstParagraph1 {
          font-size: calculate-font-size(18px);
          color: $black-color;
        }
      }
    }
    // }

    .onlineGrow {
      .growTitle {
        color: $white-color;
        font-size: calculate-font-size(calc(16px + 0.1vw));
        font-weight: 800;
      }
      .growText {
        color: $white-color;
        font-size: calculate-font-size(calc(14px + 0.1vw));
        text-align: center;
      }
    }
    .trialLink {
      width: 100% !important;
      font-size: calculate-font-size(calc(16px + 0.1vw));
    }
    .collapse {
      width: 100%;
    }
  }
}

@media only screen and (max-width: $small-screen) {
  .pageContent {
    padding: 30px 20px;

    .onlineGrow {
      padding: 40px 10px;
      .growTitle {
        color: $white-color;
        font-size: calculate-font-size(calc(14px + 0.1vw));
        font-weight: 800;
      }
      .growText {
        color: $white-color;
        font-size: calculate-font-size(calc(12px + 0.1vw));
        text-align: center;
      }
      .growLink {
        font-size: calculate-font-size(calc(14px + 0.1vw));
      }
    }
    .collapse {
      width: 100%;
    }
  }
}
