.input-with-addon {
  display: flex;
  align-items: center;
  border-radius: 4px;
  padding: 4px;
  background-color: white;
  margin-top: 10px;
  width: 50%;

  .addon {
    background-color: #ccc;
    padding: 4px 8px;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    border-right: 1px solid #ccc;
  }

  .color-input {
    border: none;
    outline: none;
    border: 1px solid #ccc;
    flex: 1;
    padding: 4px;
    border-radius: 4px;
  }
}
