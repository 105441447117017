//screens-breakpoints
$small-screen: 480px;
$tablet-screen: 768px;
$medium-screen: 1024px;
$large-screen: 1444px;

//colors
$white-color: #ffffff;
$black-color: #5b627d;
$side-background-color: #6165db6c;
$main-background-color: #6165DB;
// F0FBFF
$green-color: #90ee90;
$green-color-2: #76ff7a;
$purple-color: #5d3fd3;
$light-pink: #ff98ac;
$gray-color: #eee;
$red-color: #ff0000;

//responsive-fonts-function
@function calculate-font-size($custom-size: null) {
  $default-size: calc(16px + 0.1vw);

  @if $custom-size != null {
    @return $custom-size;
  } @else {
    @return $default-size;
  }
}

.headerBasics {
  padding-left: 15px;
  padding-right: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  > div {
    display: flex;
    align-items: center;
  }

  button {
    border: none;
    margin-right: 20px;
    color: #6165DB;
    font-size: 25px;
    .ant-btn-icon .anticon {
      font-size: 30px;
    }
  }
}
