.ant-tabs {
  width: 100%;
  .ant-tabs-nav-wrap {
    .ant-tabs-nav-list {
      width: 100%;
      .ant-tabs-tab {
        width: 100%;
        text-align: center;
        margin: 0;
        .ant-tabs-tab-btn {
          width: 100%;
          text-align: center;
          margin: 0;
        }
      }
    }
  }
}
