.billing {
  padding: 1.5rem;
}

.plans-row {
  justify-content: center;
}

.plan-column {
  margin-top: 1rem;
  margin-bottom: 1rem;
}
