@import "../../global.scss";

.cardContainer {
  width: 100%;
  padding: 10px;
  height: 90px;
  border-radius: 10px;
  display: flex;
  border: 1px solid $main-background-color;
  display: flex;
  gap: 5px;

  &:hover {
    background-color: $side-background-color;
    cursor: pointer;
  }

  .thumbnailWrapper {
    width: 80px;
    height: 100%;
    display: flex;
    align-items: center;
    .imageThumbnail {
      width: 60px;
      height: 60px;
      border-radius: 5px;
    }
  }

  .cardData {
    width: 90%;
    display: flex;
    flex-direction: column;
    height: 80px;
    // align-items: center;
    justify-content: center;

    .title {
      width: 100%;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      .titleText {
        font-size: 16px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 400px;
        margin: 0 !important;
      }
    }
    .cardInfo {
      display: flex;
      width: 100%;
      .timeWrapper {
        width: 50%;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        .text {
          font-size: 16px;
          margin: 0px 5px 0px 0px;
        }

        .timeIcon {
          margin-left: 10px;
          margin-right: 5px;
        }
      }
    }
  }

  // .contentWrapper {
  //   justify-content: space-between;
  //   align-self: flex-end;
  //   width: 100%;
  //   width: 80px;
  //   display: flex;
  //   .deleteOutlined {
  //     @extend .icon;
  //     color: red;
  //   }
  //   .playCircleOutlined {
  //     @extend .icon;
  //     color: blue;
  //   }
  // }
  // .timeWrapper {
  //   width: 100%;
  //   display: flex;
  //   justify-content: flex-end;
  //   align-items: center;
  //   margin-top: 10px;
  //   .time {
  //     font-size: 12px;
  //     font-weight: 600;
  //   }
  // }
}
