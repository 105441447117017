.headerBasics {
  padding-left: 15px;
  padding-right: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  > div {
    display: flex;
    align-items: center;
  }

  button.icon {
    border: none;
    margin-right: 20px;
    color: #6165DB;
    font-size: 25px;
    .ant-btn-icon .anticon {
      font-size: 30px;
    }
  }
}

.dashboardLayout {
  width: 100%;
  height: 100vh;
  overflow-y: scroll;
  background: white;

  .header,
  .sidebar,
  .custom-header {
    background-color: white;
  }

  .custom-header {
    @extend .headerBasics;
    @extend .headerBasics;
    &:after {
      content: "";
      position: absolute;
      bottom: 0;
      left: 80px;
      right: 0;
      border-bottom: 1px solid #c0c0c0;
    }
  }
  .header {
    @extend .headerBasics;
    &:after {
      content: "";
      position: absolute;
      bottom: 0;
      left: 200px;
      right: 0;
      border-bottom: 1px solid #c0c0c0;
    }
  }
  .sidebar {
    color: black;
    padding-top: 5px;
    border-right: 1px solid #c0c0c0;
    .ant-menu {
      font-size: 18px;
      .ant-menu-item {
        height: 60px;
        display: flex;
        align-items: center;
        .anticon {
          font-size: 18px;
        }
      }
    }
  }
  .content {
    background-color: #eee;
    padding-top: 0;
    max-height: 100vh;
    overflow-y: auto;
  }
}

@media (max-width: 576.67px) {
  .dashboardLayout {
    .header {
      &:after {
        content: "";
        position: absolute;
        bottom: 0;
        left: 80px;
        right: 0;
        border-bottom: 1px solid #c0c0c0;
      }
    }
  }
}
