body {
  margin: 0;
  background-color: white;
  // -webkit-font-smoothing: antialiased;
  // -moz-osx-font-smoothing: grayscale;
}

* {
  font-family: "Poppins", sans-serif;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

:root {
  --color-black: #5b627d;
  --color-white: #ffffff;
  --color-mainColor: #6165DB;
  --color-sideBg: #6165db6c;
}

.ant-modal-body {
  .actions {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    button:not(:last-child) {
      margin-right: 10px;
    }
  }
}

::-webkit-scrollbar {
  width: 10px; /* Width of the scrollbar */
}

/* Style the scrollbar thumb (the draggable part) */
::-webkit-scrollbar-thumb {
  background-color: #6165db6c; /* Color of the thumb */
  border-radius: 6px; /* Rounded corners for the thumb */
}

/* Style the scrollbar track when hovered */
::-webkit-scrollbar-track:hover {
  background-color: #ddd; /* Color of the track on hover */
}

/* Style the scrollbar thumb when hovered */
::-webkit-scrollbar-thumb:hover {
  background-color: #6165DB; /* Color of the thumb on hover */
}

/* Style the scrollbar corner (between the vertical and horizontal scrollbar) */
::-webkit-scrollbar-corner {
  background-color: #eee; /* Color of the corner */
}
