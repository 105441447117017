.videos {
  padding: 1.5rem;
  .header-wrapper {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    h2 {
      margin: 0;
    }
  }
  .new-video-container {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 20px;
  }

  .video-column {
    padding: 8px 0;
  }
}

.new-video-modal {
  .dropzone-container {
    margin-bottom: 15px;
  }
}
