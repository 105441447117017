@import "../../global.scss";

.sectionRow {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  // height: 385px;

  padding: 0 100px;

  .rowColDesc {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 50%;
  }
  .rowColReversed {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    max-width: 100% !important;

    .sectionImage {
      width: 100%;
      height: 100%;
      max-width: 500px;
      object-fit: cover;
    }
  }

  .rowCol {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    width: 100%;
    height: 100%;
    max-width: 100% !important;

    .sectionImage {
      width: 100%;
      height: 100%;
      max-width: 500px;
      object-fit: cover;
    }
  }
}

@media only screen and (min-width: $tablet-screen) and (max-width: $medium-screen) {
  .sectionRow {
    // flex-direction: row;
    flex-direction: row;
    padding: 0 50px !important;

    .rowColDesc {
      width: 95vw;
      max-width: 100% !important;
    }

    .rowCol {
      height: 100%;
      max-width: 100% !important;
      display: flex !important;
      justify-content: flex-end;

      .sectionImage {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }
}

@media only screen and (max-width: $tablet-screen) {
  .sectionRow {
    flex-direction: column;
    padding: 0 !important;

    .rowColDesc {
      width: 95vw;
      max-width: 100% !important;
    }
    .rowCol {
      height: 100%;
      max-width: 100% !important;
      align-items: center !important;
      margin-top: 20px;

      .sectionImage {
        width: 100%;
        height: 80%;
        object-fit: cover;
      }
    }
  }
}
