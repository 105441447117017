.subtitle-editing-background {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .effect-customization-title {
    width: 100%;
    font-size: 24px;
    text-align: left;
  }
}
