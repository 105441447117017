@import "../../global.scss";

.custom-section {
  width: 100%;
  //   padding: 0 75px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  height: 440.62px;
  background: url("./background.svg") center/cover no-repeat;
  .title-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-bottom: 20px;
    .section-title {
      font-size: 24px;
      font-weight: 400;
    }
    .strong-text {
      font-size: 24px;
      font-weight: 700;
    }
  }
  .section-paragraph {
    font-size: 18px;
    text-align: center;
    font-family: Montserrat;
    line-height: 20px;
    .youtube-highlighted {
      font-size: 18px;
      font-family: Montserrat;
      color: rgba(225, 14, 14, 0.88);
      font-weight: 700;
    }
    .tiktok-highlighted {
      color: #070606;
      font-family: Montserrat;
      font-size: 18px;
      font-weight: 700;
    }
    .instagram-highlighted {
      color: #cd2d94;
      font-family: Montserrat;
      font-size: 18px;
      font-weight: 700;
    }
    .facebook-highlighted {
      color: #273b98;
      font-family: Montserrat;
      font-size: 18px;
      font-weight: 700;
    }
  }
}

#instagram-gradient-container {
  max-width: 50px;
  max-height: 50px;
  padding: 8px;
  background: linear-gradient(
    #405de6,
    #5851db,
    #833ab4,
    #c13584,
    #e1306c,
    #fd1d1d,
    #f56040,
    #f77737,
    #fcaf45,
    #ffdc80
  );
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}

#facebook-container,
#tiktok-container,
#youtube-container {
  max-width: 50px;
  max-height: 50px;
}

@media only screen and (min-width: $tablet-screen) and (max-width: $medium-screen) {
  .custom-section {
    padding: 15px;
    background: none;
    background-color: #f0fbff;
    height: auto;
    .icons-containers {
      .logo-container {
        margin: 0 15px;
      }
    }
    .title-wrapper {
      .section-title {
        font-size: 20px;
      }
      .strong-text {
        font-size: 20px;
      }
    }
    .section-paragraph {
      font-size: 16px;
      .youtube-highlighted {
        font-size: 16px;
      }
      .tiktok-highlighted {
        font-size: 16px;
      }
      .instagram-highlighted {
        font-size: 16px;
      }
      .facebook-highlighted {
        font-size: 16px;
      }
    }
  }

  #instagram-gradient-container {
    width: 40px;
    height: 40px;
    padding: 8px;
    background: linear-gradient(
      #405de6,
      #5851db,
      #833ab4,
      #c13584,
      #e1306c,
      #fd1d1d,
      #f56040,
      #f77737,
      #fcaf45,
      #ffdc80
    );
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
  }
}
@media only screen and (max-width: $tablet-screen) {
  .custom-section {
    padding: 0 15px;
    background: none;
    background-color: #f0fbff;
    height: auto;
  }
  .title-wrapper {
    .section-title {
      text-align: center;
    }
  }
  #instagram-gradient-container {
    width: 40px;
    height: 40px;
    padding: 8px;
    background: linear-gradient(
      #405de6,
      #5851db,
      #833ab4,
      #c13584,
      #e1306c,
      #fd1d1d,
      #f56040,
      #f77737,
      #fcaf45,
      #ffdc80
    );
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
  }
}

@media only screen and (max-width: $small-screen) {
  .custom-section {
    .title-wrapper {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 100%;
      margin-bottom: 20px;
    }
  }
}
