@import "../../global.scss";
.pageContent {
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;

  .videoDetailsWrapper {
    display: grid;
    grid-template-columns: 1.3fr 0.7fr;
    height: 100%;
    width: 100%;
    overflow: hidden;
    .videoDetailsHeader {
      background-color: #F0FBFF;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 10px;
    }

    .videoPlayerWrapper {
      width: 100%;
      height: 85vh;
      overflow-y: scroll;
      padding: 0px 10px;
      background-color: $white-color;
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-direction: column;
      background-color: #F0FBFF;
    }
  }

  .tabs {
    height: 100%;
  }
}
