@import "../../global.scss";

.clip-editor-tab-content {
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-left: auto;
  margin-right: auto;
  .header-row {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
    .title-text {
      font-size: 24px;
      font-weight: 600;
    }
  }
}

.clip-editor-content-container {
  width: 98%;
  border-radius: 10px;
  padding: 10px;
  background-color: rgb(255, 255, 255);
  margin-top: 10px;
}
