@import "../../global.scss";

.processingAnim {
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.pageContent {
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;

  .contentHeader {
    display: flex;
    width: 100%;
    height: 100px;
    justify-content: space-between;
    align-items: center;

    .videoTitle {
      font-size: 24px;
      font-weight: 600;
    }
  }

  .videoDetailsWrapper {
    display: grid;
    grid-template-columns: 1.25fr 0.75fr;
    height: 100%;
    width: 100%;

    .videoPlayerWrapper {
      width: 100%;
      height: 100%;
      padding: 0px 10px;
      background-color: $white-color;
      background-color: #F0FBFF;
      .videoPlayerContent {
        display: flex;
        height: 100%;
        justify-content: space-around;
        align-items: center;
        flex-direction: column;

        .videoFooter {
          .editLink {
            color: $white-color;
            font-size: 20px;
            font-weight: 500;
            text-decoration: none;
            background-color: $main-background-color;
            padding: 8px;
            border-radius: 10px;
            &:hover {
              background-color: $side-background-color;
              color: $black-color;
            }
          }
        }

        .videoHeader {
          width: 100%;
          display: flex;
          justify-content: space-around;
          .videoTitle {
            color: $black-color;
            font-size: 20px;
            font-weight: 500;
          }
        }
      }
    }
    .clipsWrapper {
      display: flex;
      width: 100%;
      height: 100%;
      justify-content: center;
      justify-content: flex-end;
      .clipsList {
        width: 100%;
        height: 100%;
        border-radius: 20px;
        padding: 20px;
        .clips {
          height: 80vh;
          width: 100%;
          overflow-y: scroll;
          display: flex;
          flex-direction: column;
          align-items: center;
          &::-webkit-scrollbar {
            display: none;
          }
          .createCustom {
            display: flex;
            width: 100%;
            justify-content: flex-end;
            margin-top: 10px;
          }
          li {
            display: flex;
            width: 100%;
            justify-content: center;
          }
        }
        .listHeader {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 100%;
        }
      }
    }
  }
}
