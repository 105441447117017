@import "../../global.scss";

.formStyle {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 40%;
  margin-top: 20;

  .inputStyle {
    min-width: 350px;
    width: 70%;
  }
  .row {
    display: grid;
    grid-template-columns: 1fr 1fr;
    width: 70%;
    gap: 5px;
    min-width: 350px;
  }

  .mainInput {
    width: 100%;
    height: 50px;
  }
  .submitStyle {
    font-size: 18px;
    font-weight: 500;
    background-color: $main-background-color;
    height: 40px;
    width: 100%;
    margin-top: 20px;
  }
}
