@import "../../global.scss";

.plan-card {
  text-align: center;
  width: 100%;
  min-height: 360px;
  margin: 5;

  .ant-card-body {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    padding: 10px;
    min-height: 360px;
  }
  .title {
  }
  .plans-link {
    background-color: $main-background-color;
    color: $white-color;
    padding: 10px 20px;
    border-radius: 5px;
    margin-top: auto;
    border-radius: 45px;
    &:hover {
      background-color: $side-background-color;
      color: $white-color;
      cursor: pointer;
    }
  }
}
.plan-highlighted {
  box-shadow: 0 1px 2px -2px rgba(0, 0, 0, 0.16),
    0 3px 6px 0 rgba(0, 0, 0, 0.12), 0 5px 12px 4px rgba(0, 0, 0, 0.09);
  border: 1px solid #f0f0f0;
  background-color: #6165db;
}

.plan-highlighted .ant-typography {
  color: white;
}
.plan-highlighted .plans-link {
  color: black;
  background-color: white;
}
.plan-highlighted button {
  color: black;
  background-color: white;
}
