.container {
  width: 100%;
  display: grid !important;
  grid-template-columns: 1.75fr 0.5fr;
  align-items: center;
  gap: 10px;
  .selectContainer {
    width: 100%;
    .select {
      width: 100%;
    }
  }
  .colorSizeContainer {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .colorPicker,
    .sizeInput {
      height: 40px;
      display: flex;
      align-items: center;
    }
  }
}

.fontOptionsContainer {
  width: 100%;
  display: grid;
  grid-template-columns: 1.75fr 0.5fr;
  margin-top: 20px;
  gap: 10px;

  .mainOptionsContainer {
    display: flex;
    justify-content: space-between;
    .mainOptions {
      display: flex;
      justify-content: space-between;
    }
  }
  .lineHeightInput {
    justify-self: flex-end;
  }
}
