@import "../../global.scss";

.header {
  background-color: $white-color;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 120px;
  width: 100%;

  .headerStyle {
    background-color: $white-color;
    height: 100px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 1440px;
    // padding: 0 75px;

    .headerActionStyles {
      display: flex;
      align-items: center;
    }

    .text {
      font-size: calculate-font-size(16px);
      font-weight: 500;
      margin-right: 20px;
      color: $black-color;
    }

    .linkStyle {
      text-decoration: none;
      color: $black-color;
      font-size: calculate-font-size(16px);
      border: 1px solid $black-color;
      padding: 5px 20px;
      font-weight: 500;
      &:hover {
        background-color: $side-background-color;
        border: 1px solid $side-background-color;
      }
    }

    .landingLinks {
      width: 280px !important;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .loginLink {
        text-decoration: none;
        color: $black-color;
        font-size: calculate-font-size(20px);
        font-weight: 800;
        padding: 10px;
        &:hover {
          border-bottom: 5px solid $main-background-color;
        }
      }
      .startTrial {
        background-color: $main-background-color;
        color: $white-color;
        border-radius: 40px;
        text-decoration: none;
        font-size: calculate-font-size(20px);
        font-weight: 800;
        padding: 10px 20px;
      }
    }
  }
}

@media (min-width: 1440px) {
  .header {
    .headerStyle {
      padding: 0 75px;
    }
  }
}

@media (max-width: $tablet-screen) {
  .header {
    height: 180px;
    .headerStyle {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      height: 150px;

      .linkStyle {
        font-size: calculate-font-size(calc(16px + 0.01vw));
      }
    }
  }
}

@media (max-width: $small-screen) {
  .text {
    display: none;
  }
}
