.card {
  width: 100%;
  height: 150px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  gap: 50;
  border: 1px solid #cccccc;
  padding: 10px;
  margin: 10px 0px;
  border-radius: 8px;
  .row {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
  }
}
